import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import bc from 'bcryptjs';
import { Axios } from '../../../config';
import Cookies from 'js-cookie';
import { clearTraficVisitorsData } from '../traficVisitors/traficVisitorsSlice';
import { clearBanData } from '../banAutomatique/banAutomatiqueSlice';
import { clearConfigurationData } from '../configuration/configurationSlice';
import { clearDashboardData } from '../dashboard/dashboradSlice';
import { clearEmailData } from '../emailCheck/emailCheckSlice';
import { clearInformationsData } from '../information/informationSlice';
import { clearLogsData } from '../logEntry/logEntrySlice';
import { clearModulesData } from '../modules/moduleSlice';
import { clearNetworkData } from '../networkScan/networkScanSlice';
import { clearJiraData } from '../jiraTicket/jiraTicketSlice';
import { clearPatternData } from '../pattern/patternSlice';


export const register = createAsyncThunk('user/register', async (newUser, thunkAPI) => {
  try {
    const response = await Axios.post(`/user/register`, JSON.stringify(newUser), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    // console.log('data', response);

    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`

    if (response.status === 200) {
      // localStorage.setItem('token', data.token);
      return { ...data, newUser };
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const registerProspect = createAsyncThunk('user/registerProspet', async (newUser, thunkAPI) => {
  try {
    const response = await Axios.post(`/user/registerProspect`, JSON.stringify(newUser), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    // console.log('data', response);

    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`

    if (response.status === 200) {
      // localStorage.setItem('token', data.token);
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (newUser, thunkAPI) => {
    try {
      const response = await Axios.post(`/user/login`, JSON.stringify(newUser), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      // console.log("rrrrffff", response);

      const { data } = response; // Utilize destructuring to extract the `data` property

      if (response.status === 200) {
        // localStorage.setItem('token', data.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log('Error', error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const verifyMail = createAsyncThunk(
  'user/verif',
  async ({ code, email }, thunkAPI) => {
    try {
      const response = await Axios.post(`/user/verify`, { code, email }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      // console.log("rrrrffff", response);

      const { data } = response; // Utilize destructuring to extract the `data` property

      if (response.status === 200) {
        // localStorage.setItem('token', data.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log('Error', error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const requestReset = createAsyncThunk(
  'user/requestReset',
  async ({ email }, thunkAPI) => {
    try {
      const response = await Axios.post(`/user/requestReset`, { email }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      // console.log("rrrrffff", response);

      const { data } = response; // Utilize destructuring to extract the `data` property

      if (response.status === 200) {
        // localStorage.setItem('token', data.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log('Error', error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ token, password }, thunkAPI) => {
    try {
      const response = await Axios.post(`/user/resetPassword/${token}`, { password: password });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addSite = createAsyncThunk(
  'site/addSite',
  async (newSite, thunkAPI) => {
    try {
      const response = await Axios.post(`/site/addSite`, JSON.stringify(newSite), {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : { msg: "Network error" });
    }
  }
);






export const getSiteUser = createAsyncThunk('site/fetch',
  async (userId, { rejectWithValue }) => {


    try {
      let res = await Axios.get(`/site/sites?userId=${userId}`);
      let data = await res.data;

      return data;
    } catch (error) {
      throw Error(error.response.data);
    }
  });
  export const getSiteUserList = createAsyncThunk('siteUsers/fetch',
    async () => {
   try {
        let res = await Axios.get(`/site/getsitesUsers`);
        let data = await res.data;
  
        return data;
      } catch (error) {
        throw Error(error.response.data);
      }
    });
export const getSiteUserByid = createAsyncThunk('site/fetchsite',
  async (siteId, { rejectWithValue }) => {


    try {
      let res = await Axios.get(`/site/getsite?siteId=${siteId}`);
      let data = await res.data;

      return data;
    } catch (error) {
      throw Error(error.response.data);
    }
  });


export const profileUser = createAsyncThunk('profile/fetch', async () => {


  try {
    let res = await Axios.get(`/user/getUser`);
    let data = await res.data;

    return data;
  } catch (error) {
    throw Error(error.response.data);
  }
});


export const validateAccountRequest = createAsyncThunk(
  'accountValidation/validateAccountRequest',
  async (_id, userRole, { rejectWithValue }) => {
    // const token = localStorage.getItem('token');

    // const config = {
    //   headers: {
    //     Authorization: token,
    //   },
    // };

    try {
      const response = await Axios.put(`user/validateAccount?userRole=${userRole}`, { _id });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendEmail = createAsyncThunk(
  'email/send',
  async ({ toEmail, subject, message }, thunkAPI) => {
    try {
      const success = await Axios.post('email/sendEmailValidation', { toEmail, subject, message });
      if (success) {
        return { status: 'success' };
      } else {
        return thunkAPI.rejectWithValue({ status: 'error' });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({ status: 'error' });
    }
  }
);
// Action redux (sendEmailReport)
export const sendEmailReport = createAsyncThunk(
  'email/sendReport',
  async ({ toEmail, subject ,siteUrl, pdf, fileName }, thunkAPI) => {
    try {
      const success = await Axios.post('email/sendEmailRepporting', { toEmail, subject, siteUrl, pdf, fileName });
      if (success) {
        return { status: 'success' };
      } else {
        return thunkAPI.rejectWithValue({ status: 'error' });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({ status: 'error' });
    }
  }
);
export const updateApiKey = createAsyncThunk(
  'updateApiKey/updateApiKey',
  async ({ _id, daysValid }, { rejectWithValue }) => {


    try {
      const response = await Axios.post(`user/Apikey`, { _id, daysValid });

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateApiKeySite = createAsyncThunk(
  'updateApiKeySite/updateApiKeySite',
  async ({ siteId, daysValid }, { rejectWithValue }) => {


    try {
      const response = await Axios.post(`user/ApikeySite`, { siteId, daysValid });

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// Slice ou Reducer : Redux
export const updateUser = createAsyncThunk(
  'updateUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await Axios.put('user/update', userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || {});
    }
  }
);
export const updateLang = createAsyncThunk(
  'updateLang',
  async ({ userId, newLang }, { rejectWithValue }) => {
    try {
      const response = await Axios.put(`user/${userId}/language`, { newLang });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || {});
    }
  }
);
export const resendVerificationCode = createAsyncThunk(
  'resendcode',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`user/codeVerif?email=${email}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || {});
    }
  }
);
export const updateSiteUser = createAsyncThunk(
  'updateSiteUser',
  async (siteData, { rejectWithValue }) => {
    try {
      const response = await Axios.put('user/updateSite', siteData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || {});
    }
  }
);

export const getUsersInvalidate = createAsyncThunk(
  'users/fetchUnvalidated',
  async () => {
    try {
      const res = await Axios.get(`/user/users/unvalidated`);
      return res.data;
    } catch (error) {
      throw new Error(error.response && error.response.data ? error.response.data : error.message);
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async ({ userId, oldPassword, newPassword, confirmPassword }, { rejectWithValue }) => {
    try {
      const response = await Axios.put(`/user/${userId}/password`, { oldPassword, newPassword, confirmPassword });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const logout = createAsyncThunk(
  'user/logout',
  async (_, thunkAPI) => {  // Remplacez {} par _, car nous n'utilisons pas de payload ici
    try {
      const response = await Axios.get('/user/logout');  // Remplacez ceci par l'URL de votre route de déconnexion
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getuserbyid = createAsyncThunk('user/getuserbyid',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/user/user?userId=${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const getTokenbyid = createAsyncThunk('user/getTokenbyid',
//   async (userId, { rejectWithValue }) => {
//     try {
//       const response = await Axios.get(`/user/token?userId=${userId}`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
export const getuserbyEmail = createAsyncThunk('user/getuserbyEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/user/userEmail?email=${email}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteUser = createAsyncThunk('user/deleteUser',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(`/user/user?userId=${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteSite = createAsyncThunk('site/deleteSite',
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(`/site/deleteSite?siteId=${siteId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const clearStoreAndLogout = createAsyncThunk(
  'auth/clearStoreAndLogout',
  async (_, { dispatch }) => {
    dispatch(logout());
    dispatch(clearTraficVisitorsData());

    dispatch(clearBanData());
    dispatch(clearUserData());

    dispatch(clearConfigurationData());
    dispatch(clearDashboardData());
    dispatch(clearEmailData());
    dispatch(clearInformationsData());
    dispatch(clearLogsData());
    dispatch(clearModulesData());
    dispatch(clearNetworkData());
    dispatch(clearPatternData())
    dispatch(clearJiraData());    // Continue dispatching other clear actions
    // You can also handle API calls or other async operations here
    localStorage.removeItem('selectedSiteId');

  }
);
const initialState = {
  users: undefined,
  // token: localStorage.getItem('token'),
  isFetchingAuth: false,
  isSuccessAuth: false,
  loading: false,
  isErrorAuth: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  isFetchingPass: false,
  isSuccessPass: false,
  isErrorPass: false,
  isAuthAuth: false,
  errorMessage: '',
  errorMessageAdd: '',
  isFetchingRegister: false,
  isSuccessRegister: false,
  isErrorRegister: false,
  errorMessageRegister: '',
  isFetchingRegisterProspect: false,
  isSuccessRegisterProspect: false,
  isErrorRegisterProspect: false,
  errorMessageRegisterProspect: '',
  isFetchingLogin: false,
  isSuccessLogin: false,
  isErrorLogin: false,
  errorMessageLogin: '',
  errorMessageGetUser: '',
  isSuccessValidate: false,
  isErrorValidate: false,
  isFetchingValidate: false,
  isSuccessGetUsers: false,
  isErrorGetUsers: false,
  isFetchingGetUsers: false,
  listUserInvalidate: [],
  errorMessageValidation: '',
  isSuccessUpdateApiKey: false,
  isErrorUpdateApiKey: false,
  isFetchingUpdateApiKey: false,
  isSuccessUpdateApiKeySite: false,
  isErrorUpdateApiKeySite: false,
  isFetchingUpdateApiKeySite: false,
  isSuccessUpdateUser: false,
  isErrorUpdateUser: false,
  isFetchingUpdateUser: false,
  isSuccessUpdateSite: false,
  isErrorUpdateSite: false,
  isFetchingUpdateSite: false,
  isFetchingDeleteUser: false,
  userDelete: null,
  isSuccessDeleteUser: false,
  isErrorDeleteUser: false,
  isFetchingDeleteSite: false,
  siteDelete: null,
  isSuccessDeleteSite: false,
  isErrorDeleteSite: false,
  user: null,
  isFetchingGetUser: false,
  isSuccessGetUser: false,
  isErrorGetUser: false,
  userupdate: null,
  sites: null,
  isFetchingSite: false,
  isFetchingSiteClient: false,
  isSuccessSite: false,
  site: null,
  sitesList: null,
  lang: null,
  isSuccessLang: false,
  isErrorLang: false,
  isLoadingLang: false,
  isSuccessAddSite: false,
  isErrorAddSite: false,
  isLoadingAddSite: false,
  siteAdded: null,
  userByEmail: null,
  msgAlertSite: null,

  isSuccessToken: false,
  isErrorToken: false,
  isLoadingToken: false,
  isSuccessVerifCode: false,
  isErrorVerifCode: false,
  isLoadingVerifCode: false,
  isSuccessRequestReset: false,
  isErrorRequestReset: false,
  isLoadingRequestReset: false,
  isSuccessChangePassword: false,
  isErrorChangePassword: false,
  isLoadingChangePassword: false,
  token: null,
  dataverif: null,
  isSuccessVerifCodes:false,
  isErrorVerifCodes:false,
  isLoadingVerifCodes:false,
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: { clearUserData: () => initialState,
     resetAuthState: (state) => {
    state.isSuccessAuth = false;
    state.isErrorAuth = false;
  },},

  
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isFetchingRegister = true;
        state.isErrorRegister = false;
        state.isSuccessRegister = false;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        // const { data } = payload;
        // console.log(data)
        state.isFetchingRegister = false;
        state.isSuccessRegister = true;
        state.isErrorRegister = false;
        // state.users = payload?.user;
        // state.token = payload?.token;
      })
      .addCase(register.rejected, (state, { payload }) => {
        state.isFetchingRegister = false;
        state.isErrorRegister = true;
        state.isSuccessRegister = false;
        state.errorMessageRegister = payload?.msg;
      })
      .addCase(registerProspect.pending, (state) => {
        state.isFetchingRegisterProspect = true;
        state.isErrorRegisterProspect = false;
        state.isSuccessRegisterProspect = false;
      })
      .addCase(registerProspect.fulfilled, (state, { payload }) => {
        // const { data } = payload;
        // console.log(data)
        state.isFetchingRegisterProspect = false;
        state.isSuccessRegisterProspect = true;
        state.isErrorRegisterProspect = false;

      })
      .addCase(registerProspect.rejected, (state, { payload }) => {
        state.isFetchingRegisterProspect = false;
        state.isErrorRegisterProspect = true;
        state.isSuccessRegisterProspect = false;
        state.errorMessageRegisterProspect = payload?.msg;
      })
      .addCase(sendEmail.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(sendEmail.fulfilled, (state, { payload }) => {

        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;

      })
      .addCase(sendEmail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(sendEmailReport.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(sendEmailReport.fulfilled, (state, { payload }) => {

        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;

      })
      .addCase(sendEmailReport.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(resendVerificationCode.pending, (state) => {
        state.isSuccessVerifCodes=false;
        state.isErrorVerifCodes=false;
       state.isLoadingVerifCodes=true;
      })
      .addCase(resendVerificationCode.fulfilled, (state, { payload }) => {
  state.isSuccessVerifCodes=true;
        state.isErrorVerifCodes=false;
       state.isLoadingVerifCodes=false;

      })
      .addCase(resendVerificationCode.rejected, (state, { payload }) => {
       state.isSuccessVerifCodes=false;
        state.isErrorVerifCodes=true;
       state.isLoadingVerifCodes=false;
      })
      .addCase(verifyMail.pending, (state) => {
        state.isLoadingVerifCode = true;
        state.isErrorVerifCode = false;
        state.isSuccessVerifCode = false;
      })
      .addCase(verifyMail.fulfilled, (state, { payload }) => {
        state.dataverif = payload
        state.isLoadingVerifCode = false;
        state.isSuccessVerifCode = true;
        state.isErrorVerifCode = false;

      })
      .addCase(verifyMail.rejected, (state, { payload }) => {
        state.isLoadingVerifCode = false;
        state.isErrorVerifCode = true;
        state.isSuccessVerifCode = false;
        state.errorMessage=payload
      })
      .addCase(requestReset.pending, (state) => {
        state.isLoadingRequestReset = true;
        state.isErrorRequestReset = false;
        state.isSuccessRequestReset = false;
      })
      .addCase(requestReset.fulfilled, (state, { payload }) => {
        state.isLoadingRequestReset = false;
        state.isSuccessRequestReset = true;
        state.isErrorRequestReset = false;

      })
      .addCase(requestReset.rejected, (state, { payload }) => {
        state.isLoadingRequestReset = false;
        state.isErrorRequestReset = true;
        state.isSuccessRequestReset = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoadingChangePassword = true;
        state.isErrorChangePassword = false;
        state.isSuccessChangePassword = false;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.isLoadingChangePassword = false;
        state.isSuccessChangePassword = true;
        state.isErrorChangePassword = false;

      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.isLoadingChangePassword = false;
        state.isErrorChangePassword = true;
        state.isSuccessChangePassword = false;
      })
      .addCase(addSite.pending, (state) => {
        state.isLoadingAddSite = true;
        state.isErrorAddSite = false;
        state.isSuccessAddSite = false;
      })
      .addCase(addSite.fulfilled, (state, { payload }) => {

        state.isLoadingAddSite = false;
        state.isSuccessAddSite = true;
        state.isErrorAddSite = false;
        state.siteAdded = payload
        state.msgAlertSite = payload.msg

      })
      .addCase(addSite.rejected, (state, { payload }) => {
        state.isLoadingAddSite = false;
        state.isSuccessAddSite = false;
        state.isErrorAddSite = true;
        state.errorMessageAdd = payload.msg || 'Failed to add site';
        state.msgAlertSite = payload.msg
      })

      .addCase(validateAccountRequest.pending, (state) => {
        state.isFetchingValidate = true;
        state.isErrorValidate = false;
        state.isSuccessValidate = false;
      })
      .addCase(validateAccountRequest.fulfilled, (state, { payload }) => {
        state.isFetchingValidate = false;
        state.isSuccessValidate = true;
        state.isErrorValidate = false;
        state.listUserInvalidate = payload.listUserInvalidate;
      })
      .addCase(validateAccountRequest.rejected, (state, { payload }) => {
        state.isFetchingValidate = false;
        state.isErrorValidate = true;
        state.isSuccessValidate = false;
        state.errorMessageValidation = payload;
      })
      .addCase(updateApiKey.pending, (state) => {
        state.isFetchingUpdateApiKey = true;
        state.isErrorUpdateApiKey = false;
        state.isSuccessUpdateApiKey = false;
      })
      .addCase(updateApiKey.fulfilled, (state, { payload }) => {
        // console.log('updateapiky',payload.user)
        state.isFetchingUpdateApiKey = false;
        state.isSuccessUpdateApiKey = true;
        state.isErrorUpdateApiKey = false;
        state.user = payload.user;

      })
      .addCase(updateApiKey.rejected, (state, { payload }) => {
        state.isFetchingValidate = false;
        state.isErrorUpdateApiKey = true;
        state.isSuccessUpdateApiKey = false;
        state.errorMessageValidation = payload;
      })
      .addCase(updateLang.pending, (state) => {
        state.isLoadingLang = true;
        state.isErrorLang = false;
        state.isSuccessLang = false;
      })
      .addCase(updateLang.fulfilled, (state, { payload }) => {
        // console.log('updateapiky',payload.user)
        state.isLoadingLang = false;
        state.isSuccessLang = true;
        state.isErrorLang = false;
        state.lang = payload.lang;

      })
      .addCase(updateLang.rejected, (state, { payload }) => {
        state.isLoadingLang = false;
        state.isErrorLang = true;
        state.isSuccessLang = false;
      })
      .addCase(updateApiKeySite.pending, (state) => {
        state.isFetchingUpdateApiKey = true;
        state.isErrorUpdateApiKey = false;
        state.isSuccessUpdateApiKey = false;
      })
      .addCase(updateApiKeySite.fulfilled, (state, { payload }) => {
        // console.log('updateapiky',payload.user)
        state.isFetchingUpdateApiKeySite = false;
        state.isSuccessUpdateApiKeySite = true;
        state.isErrorUpdateApiKeySite = false;
        state.site = payload.site;

      })
      .addCase(updateApiKeySite.rejected, (state, { payload }) => {
        state.isFetchingUpdateApiKeySite = false;
        state.isErrorUpdateApiKeySite = true;
        state.isSuccessUpdateApiKeySite = false;
        // state.errorMessageValidationSite=payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isFetchingUpdateUser = true;
        state.isErrorUpdateUser = false;
        state.isSuccessUpdateUser = false;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.isFetchingUpdateUser = false;
        state.isSuccessUpdateUser = true;
        state.isErrorUpdateUser = false;
        state.listUserInvalidate = payload.listUserInvalidate;
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.isFetchingUpdateUser = false;
        state.isErrorUpdateUser = true;
        state.isSuccessUpdateUser = false;
        state.errorMessageValidation = payload;
      })
      .addCase(updateSiteUser.pending, (state) => {
        state.isFetchingUpdateSite = true;
        state.isErrorUpdateSite = false;
        state.isSuccessUpdateSite = false;
      })
      .addCase(updateSiteUser.fulfilled, (state, { payload }) => {
        state.isFetchingUpdateSite = false;
        state.isSuccessUpdateSite = true;
        state.isErrorUpdateSite = false;
        state.sitesList = payload.sitesList;
      })
      .addCase(updateSiteUser.rejected, (state, { payload }) => {
        state.isFetchingUpdateSite = false;
        state.isErrorUpdateSite = true;
        state.isSuccessUpdateSite = false;
        // state.errorMessageValidation=payload;
      })
      .addCase(getUsersInvalidate.pending, (state) => {
        state.isFetchingGetUsers = true;
        state.isErrorGetUsers = false;
        state.isSuccessGetUsers = false;
      })
      .addCase(getUsersInvalidate.fulfilled, (state, { payload }) => {
        state.listUserInvalidate = payload;
        state.isFetchingGetUsers = false;
        state.isSuccessGetUsers = true;

      })
      .addCase(getUsersInvalidate.rejected, (state, { payload }) => {
        state.isFetchingGetUsers = false;
        state.isErrorGetUsers = true;
        state.errorMessageGetUser = payload
      })
      .addCase(loginUser.pending, (state) => {
        state.isFetchingLogin = true;
        state.isErrorLogin = false;
        state.isSuccessLogin = false;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        // state.users = payload?.user;
        state.isFetchingLogin = false;
        state.isSuccessLogin = true;
        state.isErrorLogin = false;
        state.token = payload?.token;
        state.isAuthAuth = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        // console.log('frrrr',payload)
        state.isFetchingLogin = false;
        state.isErrorLogin = true;
        state.errorMessageLogin = payload?.msg;
        state.isAuthAuth = false;
        state.isSuccessLogin = false;


      })
      .addCase(getuserbyid.pending, (state) => {
        state.isFetchingGetUser = true;
        state.isErrorGetUser = false;
        state.isSuccessGetUser = false;
        state.user = null;
      })
      .addCase(getuserbyid.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isFetchingGetUser = false;
        state.isSuccessGetUser = true;

      })
      .addCase(getuserbyid.rejected, (state) => {
        state.isFetchingGetUser = false;
        state.isErrorGetUser = false;
        state.isSuccessGetUser = true;

      })

      .addCase(getuserbyEmail.pending, (state) => {
        state.isFetchingGetUser = true;
        state.isErrorGetUser = false;
        state.isSuccessGetUser = false;
        state.userByEmail = null;
      })
      .addCase(getuserbyEmail.fulfilled, (state, { payload }) => {
        state.userByEmail = payload;
        state.isFetchingGetUser = false;
        state.isSuccessGetUser = true;

      })
      .addCase(getuserbyEmail.rejected, (state) => {
        state.isFetchingGetUser = false;
        state.isErrorGetUser = false;
        state.isSuccessGetUser = true;

      })
      .addCase(profileUser.pending, (state) => {
        state.isFetchingAuth = true;
        state.isErrorAuth = false;
        state.isSuccessAuth = false;
      })
      .addCase(profileUser.fulfilled, (state, { payload }) => {
        state.users = payload;
        state.isFetchingAuth = false;
        state.isSuccessAuth = true;
        state.isErrorAuth = false;
        //  state.userInfo = payload;
        state.isAuthAuth = true;
      })
      .addCase(profileUser.rejected, (state, { payload }) => {
        state.users = null;
        state.isFetchingAuth = false;
        state.isErrorAuth = true;
        state.isSuccessAuth = false;
      })

      .addCase(getSiteUser.pending, (state) => {
        state.isFetchingSiteClient = true;
        state.isError = false;
        state.isSuccessSite = false;
      })
      .addCase(getSiteUser.fulfilled, (state, { payload }) => {
        state.sites = payload;

        state.isFetchingSiteClient = false;
        state.isSuccessSite = true;
        state.isError = false;
        //  state.userInfo = payload;
      })
      .addCase(getSiteUser.rejected, (state, { payload }) => {
        state.isFetchingSiteClient = false;
        state.isError = true;
        state.isSuccessSite = false;
      })
      .addCase(getSiteUserList.pending, (state) => {
        state.isFetchingSite = true;
        state.isError = false;
        state.isSuccessSite = false;
      })
      .addCase(getSiteUserList.fulfilled, (state, { payload }) => {
        state.sitesList = payload;

        state.isFetchingSite = false;
        state.isSuccessSite = true;
        state.isError = false;
        //  state.userInfo = payload;
      })
      .addCase(getSiteUserList.rejected, (state, { payload }) => {
        state.isFetchingSite = false;
        state.isError = true;
        state.isSuccessSite = false;
      })
      .addCase(getSiteUserByid.pending, (state) => {
        state.isFetchingSite = true;
        state.isError = false;
        state.isSuccessSite = false;
      })
      .addCase(getSiteUserByid.fulfilled, (state, { payload }) => {
        state.site = payload;
        state.isFetchingSite = false;
        state.isSuccessSite = true;
        state.isError = false;
        //  state.userInfo = payload;
      })
      .addCase(getSiteUserByid.rejected, (state, { payload }) => {
        state.isFetchingSite = false;
        state.isError = true;
        state.isSuccessSite = false;
      })

      .addCase(updateUserPassword.fulfilled, (state, { payload }) => {
        state.users = payload.user;
        state.isSuccessPass = true;
        state.isErrorPass = false;
      })
      .addCase(updateUserPassword.rejected, (state, { payload }) => {
        state.isErrorPass = true;
        state.errorMessage = payload;
        state.isSuccessPass = false;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.isFetchingPass = true;
        state.isSuccessPass = false;
        state.isErrorPass = false;
      })
      .addCase(deleteUser.pending, (state, { payload }) => {
        state.isFetchingDeleteUser = true;
        state.isSuccessDeleteUser = false;
        state.isErrorDeleteUser = false;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.isFetchingDeleteUser = false;
        state.isSuccessDeleteUser = true;
        state.userDelete = payload;
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.isFetchingDeleteUser = false;
        state.isErrorDeleteUser = true;
        state.isSuccessDeleteUser = false;
      })
      .addCase(deleteSite.pending, (state, { payload }) => {
        state.isFetchingDeleteSite = true;
        state.isSuccessDeleteSite = false;
        state.isErrorDeleteSite = false;
      })
      .addCase(deleteSite.fulfilled, (state, { payload }) => {
        state.isFetchingDeleteSite = false;
        state.isSuccessDeleteUser = true;
        state.siteDelete = payload;
      })
      .addCase(deleteSite.rejected, (state, { payload }) => {
        state.isFetchingDeleteSite = false;
        state.isErrorDeleteSite = true;
        state.isSuccessDeleteSite = false;
      })
      .addCase(logout.pending, (state) => {
      })

      .addCase(logout.fulfilled, (state) => {
        // token: localStorage.getItem('token'),
        state.users = null;
        state.isSuccessAuth = false;
        state.isFetchingAuth = false;
        state.loading = false;
        state.isErrorAuth = false;
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = false;
        state.isFetchingPass = false;
        state.isSuccessPass = false;
        state.sites = null;

        state.sitesList = null;
        state.site = null;
        state.isErrorPass = false;
        state.isAuthAuth = false;
        state.errorMessage = '';
        state.isFetchingRegister = false;
        state.isSuccessRegister = false;
        state.isErrorRegister = false;
        state.errorMessageRegister = '';
        state.isFetchingLogin = false;
        state.isSuccessLogin = false;
        state.isErrorLogin = false;
        state.errorMessageLogin = '';
        state.errorMessageGetUser = '';
        state.isSuccessValidate = false;
        state.isErrorValidate = false;
        state.isFetchingValidate = false;
        state.isSuccessGetUsers = false;
        state.isErrorGetUsers = false;
        state.isFetchingGetUsers = false;
        state.listUserInvalidate = [];
        state.errorMessageValidation = '';
        state.isSuccessUpdateApiKey = false;
        state.isErrorUpdateApiKey = false;
        state.isFetchingUpdateApiKey = false;
        state.isSuccessUpdateUser = false;
        state.isErrorUpdateUser = false;
        state.userupdate = null;
        state.isFetchingRegisterProspect = false;
        state.isSuccessRegisterProspect = false;
        state.isErrorRegisterProspect = false;
        state.errorMessageRegisterProspect = '';
        state.isFetchingUpdateUser = false;
        state.user = null;
        state.isFetchingGetUser = false;
        state.isSuccessGetUser = false;
        state.isErrorGetUser = false;
        state.isFetchingDeleteUser = false;
        state.isFetchingDeleteSite = false;
        state.siteDelete = null;
        state.isSuccessDeleteSite = false;
        state.isErrorDeleteSite = false;
        state.userDelete = null;
        state.isSuccessDeleteUser = false;
        state.isErrorDeleteUser = false;
        localStorage.removeItem('selectedSiteId');
        localStorage.removeItem('currentStep');
        state.token = null
        // localStorage.removeItem('t
      })
      .addCase(logout.rejected, (state, action) => {
        // state.error = action.error.message;
      });
  },
});


export const { clearUserData, resetAuthState } = userSlice.actions;


export default userSlice.reducer;
